/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Flex, Box, Heading, Text } from "rebass";
import CompanyTitleListing from "../components/CompanyTitleListing";
import Layout from "../layouts/";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Work() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Flex
          flexWrap="wrap"
          alignItems="center"
          variant="containerPage"
          mb={[0, 0, 0, 0, 0]}
        >
          <Box width={[1 / 1]}>
            <Heading as="h3" variant="catHeading" mb={[2]}>
              All Projects
            </Heading>
            <Heading as="h1" variant="bigTitle" mb={[3]}>
              WORK
            </Heading>
            <Text as="p">
              The Good, The Bad, and The Ugly &mdash; What worked and what
              didn’t.
            </Text>
          </Box>
        </Flex>
        <CompanyTitleListing listProjects={"yes"} />
        <Footer />
      </main>
    </Layout>
  );
}
